import React, { useEffect, useState } from "react";
import Profilewrap from "./style";
import { Row, Col, Container, Form } from "react-bootstrap";
import { api } from "../../../Services/api";
import PersonIcon from '@mui/icons-material/Person';
import { useOutletContext } from "react-router-dom";
import { useFetch } from "../../../Hooks/useFetch";
import { Alert, Checkbox, FormControlLabel, Snackbar } from "@mui/material";
import { client } from "../../../App";
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const initialNotificationPreference = {
    out_bid_email: false,
    ten_minutes_email: false,
    new_auction_email: false,
    out_bid_sms: false,
    new_auction_sms: false,
    ten_minutes_sms: false,
    out_bid_push_notification: false,
    ten_minutes_push_notification: false,
    new_auction_both: false,
    close_outbid_email: false,
    close_outbid_sms: false,
}

const Profile = () => {

    const [open, setOpen] = useState(false)
    const [severity, setSeverity] = useState('')
    const [alertmsg, setAlertMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [notificationPreference, setNotificationPreference] = useState(initialNotificationPreference)
    const { profile } = useOutletContext()

    const handleClick = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setSeverity('')
        setAlertMsg('')
        setOpen(false);
    }

    const { data: preferences, isLoading, isFetching } = useFetch("getUserNotificationPreference",
        "bid-leads/notification-preferences", () => { }, () => { }, false, true)

    useEffect(() => {
        if (preferences) {
            setNotificationPreference(preferences)
        }
    }, [preferences])

    const handleResponse = async (severity, responseMsg) => {
        setSeverity(severity)
        setAlertMsg(responseMsg)
    }

    const postCheckBox = async (notification_type, notification_value) => {
        const data = {
            notification_type,
            notification_value
        }
        handleClick()
        setLoading(true)
        try {
            const response = await api.post("/bid-leads/notification-preferences", data, {
                headers: {
                    authorizationtoken: localStorage.getItem("token")
                }
            })
            handleResponse("success", response.data.message)
            setLoading(false)
            client.invalidateQueries({
                queryKey: ["getUserNotificationPreference"]
            })
        } catch (error) {
            setLoading(false)
            error.response.status === 401 ? handleResponse("info", error.response?.data?.message) : handleResponse("error", error.response?.data?.message)
        }
    }

    return (
        <Profilewrap>
            {alertmsg.length !== 0 && (<Snackbar
                className="snackbar"
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}>
                <Alert onClose={handleClose} variant="filled" severity={severity}>
                    {alertmsg}
                </Alert>
            </Snackbar>)}

            <Row>
                <div className="gradientblock">
                    <Row className="mb-0">
                        <Col md={12} lg={3} xl={3} xxl={2}>
                            <div className="">
                                <div className="profile">
                                    <div className="profileLetter text-capitalize d-flex justify-content-center align-items-center">{profile ? profile?.first_name[0] :
                                        <PersonIcon className="person_icon" />}</div>
                                </div>
                            </div>
                        </Col>
                        <Col md={12} lg={2} xl={9} xxl={10} className="name-col"><h4 className="fw-bold text-white">{profile?.first_name} {profile?.last_name}</h4></Col>
                    </Row>
                </div>
            </Row>
            <Container>
                <Row className="user-details-row mt-4">
                    <div className="user-container">
                        <div className="px-4">
                            <Row>
                                <Col className="data-col">
                                    <div className="first_name pt-4"><h5 className="fw-semibold">First name</h5>
                                        <div className="pt-2 profile-data">{profile?.first_name}</div>
                                        <Col md={5}><div className="underline mt-1"></div></Col>
                                    </div>
                                </Col>
                                <Col className="data-col">
                                    <div className="last_name pt-4"><h5 className="fw-semibold">Last name</h5>
                                        <div className="pt-2 profile-data">{profile?.last_name}</div>
                                        <div className="underline mt-1"></div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="data-col">
                                    <div className="eanil_id pt-4"><h5 className="fw-semibold">Email ID</h5>
                                        <div className="pt-2 profile-data">
                                            {profile?.email}
                                        </div>
                                        <div className="underline mt-1"></div>
                                    </div>
                                </Col>
                                <Col className="data-col">
                                    <div className="phone_number pt-4 mb-5"><h5 className="fw-semibold">Phone number</h5>
                                        <div className="pt-2 profile-data">
                                            {profile?.mobile}
                                        </div>
                                        <div className="underline mt-1"></div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Row>

                <Row className="user-details-row mt-4">
                    <div className="fw-bolder mb-2 text-center">Notification Settings</div>
                    <div class="user-container">
                        <Form className="w-100 d-flex flex-column gap-2 p-4">

                            <div className="fw-bolder">Notify me when I’m outbid</div>
                            <div className="d-flex gap-sm-5 gap-0 flex-column flex-sm-row">
                                <FormControlLabel
                                    control={<Checkbox className="p-2" checkedIcon={<CheckBoxIcon className="check_box_icon" />} />}
                                    label="by email"
                                    disabled={loading || isLoading || isFetching}
                                    checked={notificationPreference.out_bid_email}
                                    onChange={(e) => {
                                        setNotificationPreference((prev) => {
                                            const preference = !prev.out_bid_email
                                            postCheckBox("out_bid_email", preference)
                                            return { ...prev, out_bid_email: preference }
                                        })
                                    }}
                                />
                                <FormControlLabel
                                    control={<Checkbox className="p-2" checkedIcon={<CheckBoxIcon className="check_box_icon" />} />}
                                    label="by SMS"
                                    disabled={loading || isLoading}
                                    checked={notificationPreference.out_bid_sms}
                                    onChange={(e) => {
                                        setNotificationPreference((prev) => {
                                            const preference = !prev.out_bid_sms
                                            postCheckBox("out_bid_sms", preference)
                                            return { ...prev, out_bid_sms: preference }
                                        })
                                    }}
                                />
                            </div>

                            <div className="fw-bolder">Notify me 10 minutes before an auction I’ve participated in is about to end</div>
                            <div className="d-flex gap-sm-5 gap-0 flex-column flex-sm-row">
                                <FormControlLabel
                                    control={<Checkbox className="p-2" checkedIcon={<CheckBoxIcon className="check_box_icon" />} />}
                                    label="by email"
                                    disabled={loading || isLoading}
                                    checked={notificationPreference.ten_minutes_email}
                                    onChange={(e) => {
                                        setNotificationPreference((prev) => {
                                            const preference = !prev.ten_minutes_email
                                            postCheckBox("ten_minutes_email", preference)
                                            return { ...prev, ten_minutes_email: preference }
                                        })
                                    }}
                                />
                                <FormControlLabel
                                    control={<Checkbox className="p-2" checkedIcon={<CheckBoxIcon className="check_box_icon" />} />}
                                    label="by SMS"
                                    disabled={loading || isLoading}
                                    checked={notificationPreference.ten_minutes_sms}
                                    onChange={(e) => {
                                        setNotificationPreference((prev) => {
                                            const preference = !prev.ten_minutes_sms
                                            postCheckBox("ten_minutes_sms", preference)
                                            return { ...prev, ten_minutes_sms: preference }
                                        })
                                    }}
                                />
                            </div>

                            <div className="fw-bolder">Notify me when a new auction starts</div>
                            <div className="d-flex gap-sm-5 gap-0 flex-column flex-sm-row">
                                <FormControlLabel
                                    control={<Checkbox className="p-2" checkedIcon={<CheckBoxIcon className="check_box_icon" />} />}
                                    label="by email"
                                    disabled={loading || isLoading}
                                    checked={notificationPreference.new_auction_email}
                                    onChange={(e) => {
                                        setNotificationPreference((prev) => {
                                            const preference = !prev.new_auction_email
                                            postCheckBox("new_auction_email", preference)
                                            return { ...prev, new_auction_email: preference }
                                        })
                                    }}
                                />
                                <FormControlLabel
                                    control={<Checkbox className="p-2" checkedIcon={<CheckBoxIcon className="check_box_icon" />} />}
                                    label="by SMS"
                                    disabled={loading || isLoading}
                                    checked={notificationPreference.new_auction_sms}
                                    onChange={(e) => {
                                        setNotificationPreference((prev) => {
                                            const preference = !prev.new_auction_sms
                                            postCheckBox("new_auction_sms", preference)
                                            return { ...prev, new_auction_sms: preference }
                                        })
                                    }}
                                />
                            </div>

                            <div className="fw-bolder">Notify me if my maximum bid is close to being outbid</div>
                            <div className="d-flex gap-sm-5 gap-0 flex-column flex-sm-row">
                                <FormControlLabel
                                    control={<Checkbox className="p-2" checkedIcon={<CheckBoxIcon className="check_box_icon" />} />}
                                    label="by email"
                                    disabled={loading || isLoading}
                                    checked={notificationPreference.close_outbid_email}
                                    onChange={(e) => {
                                        setNotificationPreference((prev) => {
                                            const preference = !prev.close_outbid_email
                                            postCheckBox("close_outbid_email", preference)
                                            return { ...prev, close_outbid_email: preference }
                                        })
                                    }}
                                />
                                <FormControlLabel
                                    control={<Checkbox className="p-2" checkedIcon={<CheckBoxIcon className="check_box_icon" />} />}
                                    label="by SMS"
                                    disabled={loading || isLoading}
                                    checked={notificationPreference.close_outbid_sms}
                                    onChange={(e) => {
                                        setNotificationPreference((prev) => {
                                            const preference = !prev.close_outbid_sms
                                            postCheckBox("close_outbid_sms", preference)
                                            return { ...prev, close_outbid_sms: preference }
                                        })
                                    }}
                                />
                            </div>
                        </Form>
                    </div>
                </Row>
            </Container>
        </Profilewrap>
    );
}

export default Profile;