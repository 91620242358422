
const constant = {
    admin: "Hashching Broker BidLead Login",
    invalid: "Invalid User",
};

export const maxBidNote = "Enter the maximum amount you’d like to pay for the lead and select Set. The BidLead system will automatically place bids on your behalf any time you are outbid, but only up to your maximum bid amount."

export const fiveMinTimerNote = "Any bids placed when there is less than 1 minute remaining on the timer will cause the timer to extend by and additional 1 minute for all leads within the auction."

export const viewOnlyNote = "Want to get in on the action? Join Hashching brokers in bidding on pre-qualified leads. With no additional fees or charges you can choose how much you pay. Call 1800 444 744 or email customercare@hashching.com.au to learn more about BidLead."

export default constant;

export const termsNConditions = {
    welcome: "Welcome to BidLead.",
    preface: "We’re excited to get you started participating in your first auction. Before you get bidding though, please make sure you understand and accept the terms below:",
    conditions: [
        "BidLead auctions will provide you with the opportunity to bid against other brokers in live lead auctions. Before you bid you will be able to preview the lead’s qualifying information.",
        "While all efforts are made to provide accurate and up-to-date information about every lead presented for auction, Hashching is not responsible for incorrect or misleading information provided by the lead during the qualification process.",
        "When you place a bid you are agreeing to purchase the lead for that amount should you win the auction.",
        "Complete payment of the winning bid must be captured by Hashching before the lead’s identifying information and contact details will be provided to you.",
        "Abusing the BidLead system by placing false bids, bidding without intention to pay, or any other actions which are undertaken (intentionally or otherwise) in a way that causes harm to the platform or other users of the platform may result in Hashching removing your access to BidLead without compensation.",
        "Hashching has an obligation to our leads to make sure their enquires are handled promptly and professionally. Improper or unprofessional conduct toward our lead.",
    ],
    note: "By clicking Accept you acknowledge you have read and agree to the above terms of use.",
}


export const releaseNotesStatic = {
    preface: "We are excited to announce a series of updates to BidLead. These changes are based on your feedback and we hope that they will simplify and enhance your experience with the platform.",
    notes: [
        { id: 1, title: "Notification Preferences", note: "There is a new option in your profile page which allows you to choose how you would like to receive notifications, whether it's via email, SMS, or both." },
        { id: 2, title: "New Notifications", note: "Two new notifications have been built into the platform. You will now be notified when you have been outbid at any point in the auction and also when there is ten minutes remaining." },
        { id: 3, title: "Extending Auction Timer", note: "We have made a change to the way our auction timer works. When a bid is placed within the last minute of the auction, the timer will now extend by one additional minute, giving you a greater opportunity to respond if you’d like to place a new bid." },
    ],
}