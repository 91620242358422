import axios from 'axios';

export const api = axios.create(
    {
        baseURL: 'https://devapi.bidlead.com.au',
        headers: {
            "x-api-key": "fnn3TYLJlU1N1VpQv064FfJK5KWhe455tBNXyLyh",
        }
    }
);

export const lambdaUrl = "https://2d8anva62e.execute-api.ap-south-1.amazonaws.com/dev"